var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          !_vm.$vuetify.breakpoint.mobile
            ? _c(
                "v-col",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { rounded: "", flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("ÇALIŞMA PLANIM")]),
                      _c("v-spacer"),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          on: { click: _vm.previousWeek },
                        },
                        [_vm._v(" mdi-arrow-left")]
                      ),
                      _vm.$vuetify.breakpoint.mobile && !_vm.loading
                        ? _c("h5", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("momentDate")(_vm.dates[0])) +
                                " - " +
                                _vm._s(_vm._f("momentDate")(_vm.dates[1])) +
                                " "
                            ),
                          ])
                        : !_vm.loading
                        ? _c("h3", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("momentDate")(_vm.dates[0])) +
                                " - " +
                                _vm._s(_vm._f("momentDate")(_vm.dates[1])) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "v-icon",
                        { staticClass: "ml-3", on: { click: _vm.nextWeek } },
                        [_vm._v(" mdi-arrow-right")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            color: "primary",
                            to: { name: "odev-planlama" },
                          },
                        },
                        [_vm._v("Ödev Planlama")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-content-between p-1",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-card-title", { staticClass: "py-0" }, [
                                    _vm._v("Çalışma Planım"),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        to: { name: "odev-planlama" },
                                      },
                                    },
                                    [_vm._v("Ödev Planlama")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-content-center",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-3",
                                      on: { click: _vm.previousWeek },
                                    },
                                    [_vm._v(" mdi-arrow-left")]
                                  ),
                                  !_vm.loading
                                    ? _c("h5", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("momentDate")(_vm.dates[0])
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm._f("momentDate")(_vm.dates[1])
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-3",
                                      on: { click: _vm.nextWeek },
                                    },
                                    [_vm._v(" mdi-arrow-right")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "", rounded: "" } },
                [
                  !_vm.loading && _vm.rows.length > 0
                    ? _c(
                        "b-table-simple",
                        {
                          ref: "hwtable",
                          style: "min-height: " + _vm.tableMinHeigth,
                          attrs: {
                            outlined: "",
                            bordered: "",
                            responsive: "",
                            "no-border-collapse": "",
                            "sticky-header": "",
                          },
                        },
                        [
                          _c(
                            "b-thead",
                            { attrs: { "head-variant": "dark" } },
                            [
                              _c(
                                "b-tr",
                                { staticClass: "text-center" },
                                _vm._l(_vm.headers, function (header) {
                                  return _c(
                                    "b-th",
                                    {
                                      key: header.key,
                                      style:
                                        header.type == "date"
                                          ? "width: 90px"
                                          : "",
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(" " + _vm._s(header.label)),
                                      ]),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            [
                              _vm._l(_vm.rows, function (row) {
                                return _c(
                                  "b-tr",
                                  {
                                    key: row.LessonName,
                                    staticClass: "text-center",
                                    attrs: {
                                      variant:
                                        row.SourceName == null
                                          ? "secondary"
                                          : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-th",
                                      {
                                        attrs: {
                                          colspan:
                                            row.SourceName != null ? 1 : 2,
                                        },
                                      },
                                      [_vm._v(_vm._s(row.LessonName))]
                                    ),
                                    row.SourceName != null
                                      ? _c("b-th", [
                                          _vm._v(_vm._s(row.SourceName)),
                                        ])
                                      : _vm._e(),
                                    _vm._l(
                                      _vm.headers.filter(
                                        (s) => s.type == "date"
                                      ),
                                      function (day) {
                                        return _c(
                                          "b-th",
                                          { key: day.key, staticClass: "py-1" },
                                          [
                                            row.AllHomeworks.filter((s) =>
                                              s.PlannedDate.includes(day.key)
                                            ).length > 0
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      top: "",
                                                      "min-width": "300",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-boldest",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "12px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                      small: "",
                                                                      to: {
                                                                        name: "odev-planlama",
                                                                        query: {
                                                                          testIds:
                                                                            row.AllHomeworks.filter(
                                                                              (
                                                                                s
                                                                              ) =>
                                                                                s.PlannedDate.includes(
                                                                                  day.key
                                                                                )
                                                                            )
                                                                              .map(
                                                                                (
                                                                                  s
                                                                                ) =>
                                                                                  s.SourceTestId
                                                                              )
                                                                              .toString(),
                                                                        },
                                                                      },
                                                                      disabled:
                                                                        row.SourceName ==
                                                                        null,
                                                                      color:
                                                                        _vm.getColor(
                                                                          row.AllHomeworks.filter(
                                                                            (
                                                                              s
                                                                            ) =>
                                                                              s.PlannedDate.includes(
                                                                                day.key
                                                                              )
                                                                          )
                                                                        ),
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  row.SourceName !=
                                                                  null
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                row.AllHomeworks.filter(
                                                                                  (
                                                                                    s
                                                                                  ) =>
                                                                                    s.PlannedDate.includes(
                                                                                      day.key
                                                                                    )
                                                                                ).reduce(
                                                                                  (
                                                                                    a,
                                                                                    b
                                                                                  ) =>
                                                                                    a +
                                                                                    b.CompletedQuestionCount,
                                                                                  0
                                                                                )
                                                                              ) +
                                                                              " /"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row.AllHomeworks.filter(
                                                                          (s) =>
                                                                            s.PlannedDate.includes(
                                                                              day.key
                                                                            )
                                                                        ).reduce(
                                                                          (
                                                                            a,
                                                                            b
                                                                          ) =>
                                                                            a +
                                                                            b.QuestionCount,
                                                                          0
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      _vm._l(
                                                        row.AllHomeworks.filter(
                                                          (s) =>
                                                            s.PlannedDate.includes(
                                                              day.key
                                                            )
                                                        ),
                                                        function (hw) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: hw.HomeworkId,
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    hw.SourceTestName
                                                                  ) +
                                                                  " (" +
                                                                  _vm._s(
                                                                    hw.PageNumbers
                                                                  ) +
                                                                  ")  " +
                                                                  _vm._s(
                                                                    hw.QuestionCount
                                                                  ) +
                                                                  " Soru "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }),
                              _c(
                                "b-tr",
                                {
                                  staticClass: "text-center",
                                  attrs: { variant: "success" },
                                },
                                [
                                  _c("b-th", { attrs: { colspan: "2" } }, [
                                    _vm._v("TOPLAM"),
                                  ]),
                                  _vm._l(
                                    _vm.headers.filter((s) => s.type == "date"),
                                    function (day) {
                                      return _c(
                                        "b-th",
                                        { key: day.key },
                                        [
                                          _vm.rows
                                            .filter(
                                              (x) =>
                                                x.SourceName != null &&
                                                x.AllHomeworks.filter((y) =>
                                                  y.PlannedDate.includes(
                                                    day.key
                                                  )
                                                ).length > 0
                                            )
                                            .reduce(
                                              (a, b) =>
                                                a +
                                                b.AllHomeworks.filter((y) =>
                                                  y.PlannedDate.includes(
                                                    day.key
                                                  )
                                                ).reduce(
                                                  (c, d) => c + d.QuestionCount,
                                                  0
                                                ),
                                              0
                                            ) > 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "font-weight-boldest",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    to: {
                                                      name: "odev-planlama",
                                                      query: {
                                                        testIds: _vm.rows
                                                          .filter(
                                                            (x) =>
                                                              x.SourceName !=
                                                                null &&
                                                              x.AllHomeworks.filter(
                                                                (y) =>
                                                                  y.PlannedDate.includes(
                                                                    day.key
                                                                  )
                                                              ).length > 0
                                                          )
                                                          .map((s) =>
                                                            s.AllHomeworks.filter(
                                                              (s) =>
                                                                s.PlannedDate.includes(
                                                                  day.key
                                                                )
                                                            ).map(
                                                              (s) =>
                                                                s.SourceTestId
                                                            )
                                                          )
                                                          .toString(),
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.rows
                                                          .filter(
                                                            (x) =>
                                                              x.SourceName !=
                                                                null &&
                                                              x.AllHomeworks.filter(
                                                                (y) =>
                                                                  y.PlannedDate.includes(
                                                                    day.key
                                                                  )
                                                              ).length > 0
                                                          )
                                                          .reduce(
                                                            (a, b) =>
                                                              a +
                                                              b.AllHomeworks.filter(
                                                                (y) =>
                                                                  y.PlannedDate.includes(
                                                                    day.key
                                                                  )
                                                              ).reduce(
                                                                (c, d) =>
                                                                  c +
                                                                  d.CompletedQuestionCount,
                                                                0
                                                              ),
                                                            0
                                                          )
                                                      ) +
                                                      " / " +
                                                      _vm._s(
                                                        _vm.rows
                                                          .filter(
                                                            (x) =>
                                                              x.SourceName !=
                                                                null &&
                                                              x.AllHomeworks.filter(
                                                                (y) =>
                                                                  y.PlannedDate.includes(
                                                                    day.key
                                                                  )
                                                              ).length > 0
                                                          )
                                                          .reduce(
                                                            (a, b) =>
                                                              a +
                                                              b.AllHomeworks.filter(
                                                                (y) =>
                                                                  y.PlannedDate.includes(
                                                                    day.key
                                                                  )
                                                              ).reduce(
                                                                (c, d) =>
                                                                  c +
                                                                  d.QuestionCount,
                                                                0
                                                              ),
                                                            0
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c("v-alert", { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          "Seçilen tarihlerde planlama yapılmış ödev bulunamadı."
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }