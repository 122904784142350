<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0" v-if="!$vuetify.breakpoint.mobile">
        <v-toolbar rounded flat>
          <v-toolbar-title>ÇALIŞMA PLANIM</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon class="mr-3" @click="previousWeek"> mdi-arrow-left</v-icon>
          <h5 class="mb-0" v-if="$vuetify.breakpoint.mobile && !loading">
            {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
          </h5>
          <h3 class="mb-0" v-else-if="!loading">
            {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
          </h3>
          <v-icon class="ml-3" @click="nextWeek"> mdi-arrow-right</v-icon>
          <v-spacer></v-spacer>
          <!--          <v-btn text color="success" @click="downloadTable">indir-->
          <!--            <v-icon small class="ml-1">mdi-download</v-icon>-->
          <!--          </v-btn>-->
          <v-btn color="primary" class="ml-2" :to="{name: 'odev-planlama'}">Ödev Planlama</v-btn>
        </v-toolbar>
      </v-col>
      <v-col v-else>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex justify-content-between p-1">
                <v-card-title class="py-0">Çalışma Planım</v-card-title>
                <v-btn color="primary" text :to="{name: 'odev-planlama'}">Ödev Planlama</v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-content-center">
                <v-icon class="mr-3" @click="previousWeek"> mdi-arrow-left</v-icon>
                <h5 class="mt-2" v-if=" !loading">
                  {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
                </h5>
                <v-icon class="ml-3" @click="nextWeek"> mdi-arrow-right</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat rounded>
          <b-table-simple v-if="!loading && rows.length > 0" ref="hwtable" outlined bordered responsive no-border-collapse sticky-header :style="'min-height: ' + tableMinHeigth">
            <b-thead head-variant="dark">
              <b-tr class="text-center">
                <b-th v-for="header in headers" :key="header.key" :style="header.type == 'date' ? 'width: 90px':''">
                  <strong> {{ header.label }}</strong></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="row in rows" :key="row.LessonName" class="text-center"
                    :variant="row.SourceName == null ? 'secondary' : ''">
                <b-th :colspan="row.SourceName != null ? 1 : 2">{{ row.LessonName }}</b-th>
                <b-th v-if="row.SourceName != null">{{ row.SourceName }}</b-th>
                <b-th v-for="day in headers.filter(s => s.type == 'date')" :key="day.key" class="py-1">
                  <v-tooltip top v-if="row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)).length > 0"
                             min-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn text small class="font-weight-boldest" v-on="on"
                             :to="{name:'odev-planlama', query:{testIds:row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)).map(s => s.SourceTestId).toString() }}"
                             :disabled="row.SourceName == null" style="font-size: 12px"
                             :color="getColor(row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)))">
                          <span v-if="row.SourceName != null">  {{
                              row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)).reduce((a, b) => a + b.CompletedQuestionCount, 0)
                            }} /</span>
                        {{
                          row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)).reduce((a, b) => a + b.QuestionCount, 0)
                        }}
                      </v-btn>
                    </template>
                    <ul>
                      <li v-for="hw in row.AllHomeworks.filter(s => s.PlannedDate.includes(day.key))"
                          :key="hw.HomeworkId">
                        {{ hw.SourceTestName }} ({{ hw.PageNumbers }})&nbsp; {{ hw.QuestionCount }} Soru
                      </li>
                    </ul>
                  </v-tooltip>
                </b-th>
              </b-tr>
              <b-tr variant="success" class="text-center">
                <b-th colspan="2">TOPLAM</b-th>
                <b-th v-for="day in headers.filter(s => s.type == 'date')" :key="day.key">
                  <v-btn v-if=" rows.filter(x => x.SourceName != null && x.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).length > 0)
                          .reduce((a, b) => a + b.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).reduce((c, d) => c + d.QuestionCount, 0), 0) > 0"
                         text small class="font-weight-boldest" style="font-size: 12px"
                         :to="{name:'odev-planlama', query:{testIds: rows.filter(x => x.SourceName != null && x.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).length > 0)
                         .map(s => s.AllHomeworks.filter(s => s.PlannedDate.includes(day.key)).map(s => s.SourceTestId)).toString() }}">

                    {{
                      rows.filter(x => x.SourceName != null && x.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).length > 0)
                          .reduce((a, b) => a + b.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).reduce((c, d) => c + d.CompletedQuestionCount, 0), 0)
                    }} /
                    {{
                      rows.filter(x => x.SourceName != null && x.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).length > 0)
                          .reduce((a, b) => a + b.AllHomeworks.filter(y => y.PlannedDate.includes(day.key)).reduce((c, d) => c + d.QuestionCount, 0), 0)
                    }}
                  </v-btn>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <v-alert v-else type="info" text >Seçilen tarihlerde planlama yapılmış ödev bulunamadı.</v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/core/services/api.service";
import moment from 'moment'
// import Vue from 'vue';
// import VueHtml2Canvas from 'vue-html2canvas';
// Vue.use(VueHtml2Canvas);

export default {
  components: {},
  data() {
    return {
      totalRows: 0,
      rows: [],
      groupedRows: [],
      loading: true,
      filters: {},
      dates: [],
      options: {sortBy: ['EndDate'], sortDesc: [false]},
      startOfWeek: moment().startOf('isoWeek').format('YYYY-MM-DD'),
      headers: [
        {key: 'LessonName', label: 'Ders'},
        {key: 'SourceName', label: 'Kaynak'},
      ],
      totalRow: {
        LessonName: '',
        SourceName: '',
        Homeworks: []
      }
    }
  },
  created() {
    var date = this.getSaturday(new Date().addDays(-7))
    this.dates[0] = date.toISOString().slice(0, 10)
    this.dates[1] = date.addDays(8).toISOString().slice(0, 10)
    this.setHeaders(this.dates[0], this.dates[1])
  },
  mounted() {
    this.getData()
  },
  filters: {
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },
  computed: {
    tableMinHeigth() {
      return this.$vuetify.breakpoint.mobile ? 'calc(100vh - 300px)' : 'calc(100vh - 200px)'
    }
  },
  methods: {
    setHeaders(startDate, endDate) {
      this.headers = [
        {key: 'LessonName', label: 'Ders'},
        {key: 'SourceName', label: 'Kaynak'},
      ]
      let date = moment(startDate)
      while (date <= moment(endDate)) {
        this.headers.push({
          key: date.format('YYYY-MM-DD'),
          label: date.locale('tr').format('Do MMMM dddd'),
          type: 'date',
        })
        date = date.add(1, 'days')
      }
    },

    getData() {
      this.loading = true
      this.setHeaders(this.dates[0], this.dates[1])
      ApiService.setHeader()
      ApiService.post('api/Homework/student-homework-planing-report', {
        StartDate: this.dates[0],
        EndDate: this.dates[1]
      })
          .then((data) => {
            this.rows = data.data
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },

    previousWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getSaturday(d.isValid() ? d : new Date())

      d = d.addDays(-7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(8).toISOString().slice(0, 10)
      this.$emit('updateDates', this.dates)

      this.getData()
    },
    nextWeek() {
      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getSaturday(d.isValid() ? d : new Date())

      d = d.addDays(7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(8).toISOString().slice(0, 10)

      this.getData()
    },

    getSaturday(d) {
      d = new Date(d)
      if (d.getHours() < 3)
        d.setHours(d.getHours() + 6)
      var day = d.getDay()
      // var diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      var diff = d.getDate() - day + (day == 0 ? -6 : 6) // adjust when day is sunday

      return new Date(d.setDate(diff))
    },

    // downloadTable() {
    //   const el = this.$refs.hwtable.$el;
    //   // add option type to get the image version
    //   // if not provided the promise will return 
    //   // the canvas.
    //   const options = {
    //     type: 'dataURL'
    //   }
    //   this.$html2canvas(el, options).then((data) => {
    //     // create a new image and downlload
    //     // the dataURL as file.
    //     const img = new Image();
    //     img.src = data;
    //     const link = document.createElement('a');
    //     link.download = 'calisma_plani.png';
    //     link.href = data;
    //     link.click();
    //   });
    // },

    getColor(hw) {
      if (hw.length == 0)
        return 'grey'
      else if (hw.filter(s => s.CompletedQuestionCount == 0).length > 0)
        return 'red'
      else if (hw.filter(s => s.CompletedQuestionCount < s.QuestionCount).length > 0)
        return 'orange'
      else
        return 'green'
    }
  }
}
</script>
